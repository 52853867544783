.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name {
  color: var(--clr-primary);
}

/* .about__role {
  margin-top: 0.5em;
} */

.about__desc {
  font-size: 1rem;
  max-width: 600px;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem !important;
}

.email__link{
  margin-top: 1rem !important;
}

.about__contact .MuiSvgIcon-root{
  font-size: 3rem !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
  justify-content: center;
  align-items: center;
  text-align: center; 
}

.about .link--icon {
  margin-right: 20em;
  gap: 20px;
  transform: scale(1.8);
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}

/* .about__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
} */

.about__text {
  justify-content: center;
  align-items: center;
  text-align: center; 
}

.about__image {
  margin-top: 1em;
  margin-bottom: 1em;  
}
